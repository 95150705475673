import React from "react";
import "./requisites.scss";
import Button from "@components/button";
import logo from "@img/logo.svg";
import { useStaticQuery, graphql } from "gatsby";

const Requisites = () => {
	const currentYear = new Date().getFullYear();

	const requisites = useStaticQuery(graphql`
		{
			allStrapiRequisite {
				nodes {
					id
					title
					value
				}
			}
		}
	`).allStrapiRequisite.nodes;

	const print = () => {
		window.print();
	};

	return (
		<section className="full-width requisites">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<h2>Реквизиты</h2>
					</div>

					<div className="section-print col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<img
							className="logo-print"
							src={logo}
							alt="Логотип транспортной карты"
						/>
						<h4>АО «Социальная карта»</h4>
						<ul className="simple-table">
							{requisites &&
								requisites.length &&
								requisites.map(({ title, value }) => (
									<li key={value} className="simple-table__item">
										<div className="simple-table__item__title">
											<p>{title}</p>
										</div>
										<div className="simple-table__item__value">
											<p>{value}</p>
										</div>
									</li>
								))}
						</ul>
						<p className="copyright">OА «Социальная карта» {currentYear}</p>
					</div>

					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<Button onClick={print} className="button" title="Распечатать" />
					</div>
				</div>
			</div>
		</section>
	);
};

export default Requisites;
